import { datadogRum } from '@datadog/browser-rum';
import { Layout } from 'components/access-code/common';
import {
  Introduction,
  HomePageInfo,
  SiteFooter,
} from 'components/access-code/pages/home';
import { graphql } from 'gatsby';
import React from 'react';

import AnalyticsPageMeta from '../components/common/AnalyticsPageMeta';
import SEO from '../components/common/SEO';
import EFHomePage from '../components/eligibility-file/pages/home';

export const Head = () => <SEO title="Home" />;
const Home = ({ data }) => {
  const {
    heroText,
    welcomeText,
    welcomeTextLong,
    customerLogo,
    covidOnlyPortal,
    desktopCustomerBanner,
    mobileCustomerBanner,
    whatToExpectDetails,
    eligibilityFilePortal,
    partnerName,
    footerInfo,
  } = data.contentfulOptInLandingPage;

  // Set context for all events collected
  const portalType = eligibilityFilePortal ? 'eligibility_file' : 'access_code';
  datadogRum.setGlobalContextProperty('portal_type', portalType);

  const welcomeTextString = welcomeTextLong?.welcomeTextLong || welcomeText;

  if (eligibilityFilePortal) {
    return (
      <EFHomePage
        partnerName={partnerName}
        heroText={heroText}
        heroBg={desktopCustomerBanner}
        customerLogo={customerLogo?.gatsbyImageData?.images?.fallback?.src}
        welcomeHeading={welcomeText}
        welcomeContent={welcomeTextLong?.welcomeTextLong}
        whyEverlywellItems={data.allContentfulContentWithImage?.nodes}
        footerInfo={footerInfo?.footerInfo}
      />
    );
  }

  return (
    <Layout>
      <AnalyticsPageMeta pageName={'home'} />

      <Introduction
        heroText={heroText}
        welcomeMsg={welcomeTextString}
        customerLogo={customerLogo?.gatsbyImageData?.images?.fallback?.src}
        covidOnlyPortal={covidOnlyPortal}
        customerBanner={{
          desktop: desktopCustomerBanner,
          mobile: mobileCustomerBanner,
        }}
      />
      <HomePageInfo
        covidOnlyPortal={covidOnlyPortal}
        whatToExpectList={whatToExpectDetails}
      />
      <SiteFooter />
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery($brandId: String!) {
    contentfulOptInLandingPage(brandId: { eq: $brandId }) {
      partnerId
      brandId
      partnerName
      heroText
      welcomeText
      covidOnlyPortal
      eligibilityFilePortal
      welcomeTextLong {
        welcomeTextLong
      }
      customerLogo {
        gatsbyImageData(cropFocus: CENTER)
      }
      desktopCustomerBanner {
        gatsbyImageData(layout: FIXED)
      }
      mobileCustomerBanner {
        gatsbyImageData(layout: FIXED)
      }
      whatToExpectDetails {
        heading
        subheading
        image {
          gatsbyImageData
        }
      }
      footerInfo {
        footerInfo
      }
    }
    allContentfulContentWithImage(
      filter: { slug: { regex: "/opt-in-landing-page-why-everlywell-/" } }
      sort: { fields: [number] }
    ) {
      nodes {
        number
        heading
        subheading
        image {
          gatsbyImageData
        }
      }
    }
  }
`;

export default Home;
